import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useFormKeypress, useMounted } from '@Base/hooks';
import { EditButton, CreateButton, CancelButton } from '@Base/Buttons';
import validation, { mapErrors } from '@JS/utils/validation';
import { retryableAPICall } from '@API/common-api-utils';
import { Confirmation } from '@Base/Modal';
import { addPolarisUser, removePolarisUser, addKeyMap } from '@JS/actions/polarisActions';
import {
  getLinkedAccount,
  unlinkAccount,
  linkAccount,
  updateLinkedAccount,
  updateKeyMapping,
  updateAccountKeyMapping,
} from '@API/Integrations/PolarisAPI';
import PolarisKeyMapping from './PolarisKeyMapping';
import { requestStatuses } from '@JS/constants/requestStatuses';
import { trimFormData } from '@JS/utils/general-utils';
import { EnhancedCard, IntegrationInput, EnhancedCardTitle, PageColumn } from '../../Common';

const HIDDEN_VAL = '*****';

async function accountProxy(domainUrl, opts = {}) {
  const { edit = false, unlink = false } = opts;
  let resp;

  if (unlink) {
    resp = await retryableAPICall(() => unlinkAccount());
  } else if (domainUrl) {
    if (edit) {
      resp = await retryableAPICall(() => updateLinkedAccount(domainUrl));
    } else {
      resp = await retryableAPICall(() => linkAccount(domainUrl));
    }
  } else {
    resp = await retryableAPICall(() => getLinkedAccount());
  }

  return resp;
}

async function updateKeyMap(mapObj = {}, onSuccess = () => {}, onError = () => {}, updateMap) {
  if (Object.keys(mapObj).length) {
    if (updateMap) {
      const updatedAccKeys = Object.entries(mapObj).reduce((acc, [accId, accKeys]) => {
        const { public: publicKey, private: privateKey } = accKeys;
        const isSet = publicKey === HIDDEN_VAL && privateKey === HIDDEN_VAL;

        if (!isSet) return [...acc, { [accId]: { public: publicKey, private: privateKey } }];

        return acc;
      }, []);

      if (updatedAccKeys.length) {
        const respArr = await Promise.all(
          updatedAccKeys.map((accKeyPairs) => {
            const [entries] = Object.entries(accKeyPairs);
            const [accId, keyPairs] = entries;
            return retryableAPICall(() => updateAccountKeyMapping(accId, keyPairs));
          }),
        );

        const hasRespError = respArr.some((resp) => typeof resp === 'string' && resp.length);

        if (hasRespError) {
          const hasNotFoundError = respArr.some((resp) => resp === 'NOT_FOUND_ERROR');

          if (!hasNotFoundError) {
            toast.error('Error updating site mappings. Please try again later or contact support');
          }

          onError();
        } else {
          onSuccess();
        }
      } else {
        onSuccess();
      }
    } else {
      const resp = await retryableAPICall(() => updateKeyMapping(mapObj));

      if (typeof resp === 'string' && resp.length) {
        if (resp !== 'NOT_FOUND_ERROR') {
          toast.error('Error updating site mappings. Please try again later or contact support');
        }
        onError();
      } else {
        onSuccess(resp);
      }
    }
  }
}

function PolarisAdmin({
  domainUrl,
  connected,
  accountAccess,
  accountIdToSiteId: accKeyMap,
  setPolarisDomain,
  deletePolarisDomain,
  setSiteKeys,
}) {
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    setFormData({ domainUrl });
    if (connected) setIsAuthorised(true);
  }, [domainUrl, connected]);

  useEffect(() => {
    if (isMounted()) {
      const fetchData = async () => {
        const resp = await accountProxy();

        if (typeof resp === 'string') {
          if (resp !== 'NOT_FOUND_ERROR') {
            toast.error('Error fetching account information. Please try again later or contact support');
          }
        } else {
          const { domainUrl: domain, accountIdToSiteId } = resp;
          setPolarisDomain(domain, accountIdToSiteId);
        }
      };
      if (!connected) fetchData();
    }
  }, [connected, isMounted, setPolarisDomain]);

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  async function handleSave(editCreds, unlink) {
    setIsSaving(true);

    const trimmedData = trimFormData(formData);

    const errObj = validation([{ id: 'domainUrl', required: true, url: true }], trimmedData);

    const { messages, hasErrors } = mapErrors(errObj);
    if (!unlink) setErrors(messages);

    if (!hasErrors || unlink) {
      const { domainUrl: domain } = trimmedData;

      const resp = await accountProxy(domain, {
        edit: editCreds,
        unlink,
      });

      if (typeof resp === 'string' && resp.length) {
        let errMsg = 'Error submitting data. Please try again later or contact support';

        if (resp === requestStatuses.INVALID_CREDENTIALS) {
          errMsg = 'Error submitting data. Invalid Domain. Please correct and try again.';
        }

        toast.error(errMsg);
      } else if (typeof resp === 'string' && !resp.length) {
        const successMsg = `Account ${unlink ? 'Unlinked' : 'Linked'} successfully`;

        toast.success(successMsg);
        setIsAuthorised(!unlink);
        setIsEditing(false);

        if (unlink) {
          deletePolarisDomain();
        } else {
          setPolarisDomain(domain);
        }
      }
    }

    setIsSaving(false);
  }

  return (
    <>
      <PageColumn permissions={['polaris:admin']}>
        <EnhancedCard>
          <EnhancedCardTitle
            title="Polaris Integration"
            subtitle="Manage your connection details to pass candidates to Polais"
          />
          <Form innerRef={formRef}>
            <IntegrationInput
              label="Domain URL"
              id="domainUrl"
              value={formData.domainUrl || ''}
              onChange={(val) => handleChange('domainUrl', val)}
              error={errors.domainUrl}
              isAuthorised={isAuthorised}
              isEditing={isEditing}
            />
            {isAuthorised && !isEditing ? (
              <Fragment>
                <EditButton
                  className="mt-2"
                  floatRight={false}
                  label="Edit Credentials"
                  action={() => setIsEditing(true)}
                />
                <CancelButton
                  className="mt-2"
                  label="Unlink Account"
                  isLoading={isSaving}
                  disabled={isSaving}
                  action={(e) => {
                    e.preventDefault();
                    setIsConfirmOpen(true);
                  }}
                />
              </Fragment>
            ) : (
              <CreateButton
                className="mt-2"
                label={isSaving ? 'Authenticating...' : 'Link Account'}
                isLoading={isSaving}
                disabled={isSaving}
                floatRight={false}
                action={(e) => {
                  e.preventDefault();
                  handleSave(isEditing);
                }}
              />
            )}
            {isEditing && (
              <CancelButton
                className="mt-2 ms-2"
                isLoading={isSaving}
                disabled={isSaving}
                floatRight={false}
                action={() => {
                  setIsEditing(false);
                  setErrors({});
                }}
              />
            )}
          </Form>
          {connected && (
            <Fragment>
              <hr />
              <PolarisKeyMapping
                hiddenValueString={HIDDEN_VAL}
                accountAccess={accountAccess}
                accountIdToSiteId={accKeyMap}
                onUpdate={(mapObj, onComplete, updateMap) => {
                  if (Object.keys(mapObj).length) {
                    updateKeyMap(
                      mapObj,
                      () => {
                        toast.success('Access Keys updated successfully');
                        onComplete();

                        const hiddenVals = Object.entries(mapObj).reduce(
                          (acc, [accId, keys]) => ({
                            ...acc,
                            [accId]: {
                              public: keys.public.length ? HIDDEN_VAL : '',
                              private: keys.private.length ? HIDDEN_VAL : '',
                            },
                          }),
                          {},
                        );

                        setSiteKeys(hiddenVals);
                      },
                      () => onComplete(),
                      updateMap,
                    );
                  }
                }}
              />
            </Fragment>
          )}
        </EnhancedCard>
      </PageColumn>
      <Confirmation
        show={isConfirmOpen}
        content="Are you sure you want to Unlink account?"
        cancelCallback={() => setIsConfirmOpen(false)}
        confirmCallback={() => {
          handleSave(false, true);
          setIsConfirmOpen(false);
        }}
      />
    </>
  );
}

PolarisAdmin.propTypes = {
  domainUrl: PropTypes.string,
  connected: PropTypes.bool,
  accountAccess: PropTypes.arrayOf(PropTypes.shape()),
  accountIdToSiteId: PropTypes.shape(),
  setPolarisDomain: PropTypes.func,
  deletePolarisDomain: PropTypes.func,
  setSiteKeys: PropTypes.func,
};

PolarisAdmin.defaultProps = {
  domainUrl: '',
  connected: false,
  accountAccess: [],
  accountIdToSiteId: {},
  setPolarisDomain: () => {},
  deletePolarisDomain: () => {},
  setSiteKeys: () => {},
};

function mapStateToProps(state) {
  const {
    polaris: { domainUrl, connected, accountIdToSiteId },
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;

  return {
    domainUrl,
    connected,
    accountIdToSiteId,
    accountAccess: !accountAccess.length
      ? [{ accountId: activeAccountId, accountName: activeAccountName }]
      : accountAccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPolarisDomain: (domainUrl, accountIdToSiteId) => {
      dispatch(addPolarisUser(domainUrl, accountIdToSiteId));
    },
    deletePolarisDomain: () => {
      dispatch(removePolarisUser());
    },
    setSiteKeys: (accountIdToSiteId) => {
      dispatch(addKeyMap(accountIdToSiteId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PolarisAdmin);
