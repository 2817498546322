import { requestStatuses } from '@JS/constants/requestStatuses';
import { store } from '@JS/store';
import { doLogout, doRenewSession } from '@JS/auth/AuthUtils';

export const mapErrorResponse = (response) => {
  if (!response) {
    return requestStatuses.GENERAL_ERROR;
  }
  switch (response.status) {
    case 400:
      return requestStatuses.INVALID_CREDENTIALS;
    case 401:
      return requestStatuses.INVALID_CREDENTIALS;
    case 403:
      return requestStatuses.PERMISSION_DENIED;
    case 500 || 501:
      return requestStatuses.GENERAL_ERROR;
    case 503:
      return requestStatuses.MAINTENANCE;
    case 404:
      return requestStatuses.NOT_FOUND_ERROR;
    case 409:
      return requestStatuses.ALREADY_EXISTS_ERROR;
    case 410:
      return requestStatuses.EXPIRED_ERROR;
    case 412:
      return requestStatuses.PRECONDITION_FAILED;
    default:
      return requestStatuses.OFFLINE_ERROR;
  }
};

export const mapLoginErrorResponse = (response) => {
  if (!response) {
    return requestStatuses.GENERAL_ERROR;
  }
  switch (response.status) {
    case 400:
      return requestStatuses.INVALID_CREDENTIALS;
    case 423:
      return requestStatuses.ACCOUNT_LOCKED;
    default:
      return mapErrorResponse(response);
  }
};

/**
 * Attempts to handle an error in a generic way
 * @param error - The error (type of response status to handle)
 * @param retryCallback - Some function to execute to retry should the error be authentication
 * @param errorCallback - The function to call to set an error
 */
export async function handleErrors(error, retryCallback, errorCallback) {
  switch (error) {
    case requestStatuses.NOT_FOUND_ERROR:
      window.history.pushState({}, '/404', '/404');
      window.location.reload();
      break;
    case requestStatuses.INVALID_CREDENTIALS:
      await doLogout();
      break;
    default:
      if (errorCallback) {
        errorCallback();
      } else {
        window.history.pushState({}, '/500', '/500');
        window.location.reload();
      }
  }
}

export const getCommonHeaders = (accountId) => {
  const {
    userData: {
      userAuth: { token },
      userDetails: { activeAccountId },
    },
  } = store.getState();
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json; charset=utf-8',
    'Account-ID': accountId || activeAccountId,
  };
};

export const getCommonHeadersNoContentType = (accountId) => {
  const {
    userData: {
      userAuth: { token },
      userDetails: { activeAccountId },
    },
  } = store.getState();
  return {
    Authorization: `Bearer ${token}`,
    'Account-ID': accountId || activeAccountId,
  };
};

const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

// https://dev.to/ycmjason/javascript-fetch-retry-upon-failure-3p6g#es7-asyncawait-recursive
async function apiRetry(apiCall, n) {
  try {
    return await apiCall();
  } catch (error) {
    const { response = {} } = error;
    const { status } = response;

    if (status === 401) {
      if (n === 0) {
        await doLogout();
        return mapErrorResponse(response);
      }
      const isNewSession = await doRenewSession();
      if (!isNewSession) return false;
    }

    if (n === 0 || /400|404|409/.test(status)) {
      if (status === 404 || status === 403) return mapErrorResponse(response);

      await sleep(1000);
      return mapErrorResponse(response);
    }

    return apiRetry(apiCall, n - 1);
  }
}

/**
 * Allows us to make retryable API calls (3 times), if the users token is invalid we attempt to refresh
 * If that fails we log them out. If the error isn't retryable it's returned immediately.
 * @param apiCall - The api call to make
 * @returns result - Either a string with an error type or the result data.
 */
export async function retryableAPICall(apiCall, n = 2) {
  const result = await apiRetry(apiCall, n);
  return result;
}
