import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

const {
  atr,
  api,
  auth: { tenantId },
} = config;

/**
 * Gets a the company associated with the logged in user
 * @param getCompanyProfileForAccount
 */
export async function getATR(id) {
  const result = await axios.get(`${api.middlewareAPIURL}${atr.atrApprovalPath}/${id}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Tenant: tenantId,
    },
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

/**
 * Gets a the company associated with the logged in user
 * @param getAdminATR
 */
export async function getAdminATR(keywords) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${atr.atrSearchPath}`,
    {
      name: keywords,
    },
    {
      headers: getCommonHeaders(),
    },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

/**
 * Gets a the company associated with the logged in user
 * @param saveATRUser
 */
export async function saveATRUser(data, id) {
  let result;
  if (id) {
    result = await axios.put(
      `${api.middlewareAPIURL}${atr.atrAdminPath}`,
      { ...data, id },
      {
        headers: getCommonHeaders(),
      },
    );
  } else {
    result = await axios.post(`${api.middlewareAPIURL}${atr.atrAdminPath}`, data, {
      headers: getCommonHeaders(),
    });
  }

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function deleteATR(id) {
  if (id) {
    const result = await axios.delete(`${api.middlewareAPIURL}${atr.atrAdminPath}/${id}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}


export async function submitATR(id, status, reason) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${atr.atrApprovalPath}`,
    {
      token: id,
      status,
      reason,
    },
    {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Tenant: tenantId,
      },
    },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function submitAuthedATR(id, status, reason) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${atr.atrAuthedApprovalPath}`,
    {
      token: id,
      status,
      reason,
    },
    {
        headers: getCommonHeaders(),
    },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}


export async function rerequestATR(id, reason) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${atr.atrRerequestPath}`,
    {
      vacancyId: id,
      reason: reason,
    },
    {
      headers: getCommonHeaders(),
    },
  );

  if (result.status === 201 || result.status === 200) return result;

  return null;
}
