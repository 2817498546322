import { UserRoundPen, Users, Cog, Blocks } from 'lucide-react';

export const titleLookup = {
  'personal-information': 'Personal Information',
  'job-boards': 'Job Boards',
  branding: 'Branding',
  'company-profile': 'Company Profile',
  vacancy: 'Vacancy Settings',
  forms: 'Forms',
  edocs: 'eDocs',
  funnels: 'Funnels',
  'email-templates': 'Email Templates',
  'vacancy-templates': 'Vacancy Templates',
  schedule: 'Schedule',
  locations: 'Locations',
  tags: 'Tags',
  workflows: 'Automated Workflows',
  RestrictedATR: 'Authorisation to Recruit',
  profile: 'Profile',
  accounts: 'Account Management',
  users: 'User Management',
  SSO: 'Single Sign On',
  fourth: 'Fourth Integration',
  polaris: 'Polaris Integration',
  hollweg: 'Hollweg Integration',
  atr: 'ATR',
  access: 'Access Integration',
  s4: 'S4 Integration',
  'same-system': 'Same System Integration',
  ubeya: 'Ubeya Integration',
};

export const sectionLookup = {
  'personal-prefs': {
    title: 'Personal Preferences',
    icon: <UserRoundPen size={20} />,
    submenu: [
      { title: 'Job Board Settings', uri: 'job-boards' },
      { title: 'Personal Information', uri: 'personal-information' },
    ],
  },
  'acc-settings': {
    title: 'Account Settings',
    icon: <Cog size={20} />,
    submenu: [
      { title: 'ATR', uri: 'atr', permissions: ['admin:atr:read'] },
      { title: 'Branding', uri: 'branding', permissions: ['admin:branding'] },
      { title: 'Company Profile', uri: 'company-profile' },
      { title: 'eDocs', uri: 'edocs', permissions: ['admin:edoc:read'] },
      { title: 'Email Templates', uri: 'email-templates', permissions: ['admin:emails:read'] },
      { title: 'Forms', uri: 'forms', permissions: ['admin:forms:read'] },
      { title: 'Funnels', uri: 'funnels', permissions: [] },
      { title: 'Locations', uri: 'locations', permissions: ['admin:locations:read'] },
      {
        title: 'Rejection Reasons',
        uri: 'rejection-reasons',

        permissions: ['admin:rejectionreasons:update'],
      },
      { title: 'Schedule', uri: 'schedule', permissions: ['admin:schedule:read'] },
      { title: 'Tags', uri: 'tags', permissions: ['candidate:tags'] },
      { title: 'Vacancy Settings', uri: 'vacancy', permissions: [] },
      {
        title: 'Vacancy Templates',
        uri: 'vacancy-templates',

        permissions: ['admin:vacancytemplates:read'],
      },
      { title: 'Workflows', uri: 'workflows', permissions: ['admin:workflow:read'] },
      { title: 'Advanced Workflows', uri: 'advanced-workflows', permissions: ['admin:workflow:read'] },
    ],
  },
  'acc-management': {
    icon: <Users size={20} />,
    title: 'Account Management',
    submenu: [
      { title: 'Accounts', uri: 'accounts', label: 'Accounts' },
      { title: 'Single Sign On', uri: 'SSO', permissions: ['admin:idp:create'] },
      { title: 'Users', uri: 'users' },
    ],
  },
  integrations: {
    icon: <Blocks size={20} />,
    title: 'Integrations',
    submenu: [
      { title: 'Access', uri: 'access', label: 'Access Group', permissions: ['admin:accessgrp'] },
      { title: 'Fourth', uri: 'fourth', label: 'Fourth', permissions: ['fourth:admin'] },
      { title: 'Hollweg', uri: 'hollweg', label: 'Hollweg', permissions: ['pth:create'] },
      { title: 'Polaris', uri: 'polaris', label: 'Polaris', permissions: ['polaris:admin'] },
      { title: 'Rota Ready', uri: 'rota-ready', label: 'Rota Ready', permissions: ['admin:rota'] },
      { title: 'DBS Check', uri: 'dbs-check', label: 'DBS Check', permissions: ['dbs:admin'] },
      { title: 'S4', uri: 's4', label: 'S4', permissions: ['s4:admin'] },
      { title: 'Ubeya', uri: 'ubyea', label: 'Ubeya', permissions: ['ubeya:admin'] },
    ],
  },
};

export const accountSwitchSections = [
  'schedule',
  'email-templates',
  'vacancy-templates',
  'forms',
  'locations',
  'workflows',
];
