import browserStore from 'store2';

import { localStorageNames } from '@JS/constants/storageNames';
import { isJSON } from '@JS/utils/general-utils';
import { sectionLookup } from '../Dashboard/dashboardConstants';

const { ADMIN_SECTION } = localStorageNames;

export function isEditable(createdByaccountId, activeAccountId, readOnly) {
  if (createdByaccountId === activeAccountId) return true;
  return !readOnly;
}

export function isDeletable(createdByaccountId, activeAccountId) {
  return createdByaccountId === activeAccountId;
}

export function setLocalStore(sections, page) {
  const storeArr = sections.map((section) => {
    let pageStr = '';

    const parentSection = Object.entries(sectionLookup).reduce((acc, [name, value]) => {
      if (value?.submenu.find(({ uri }) => uri === page)) return value;
      return name;
    }, '');

    if (parentSection) pageStr = page;
    return { section, page: pageStr };
  });

  browserStore(ADMIN_SECTION, JSON.stringify(storeArr));
}

export function getLocalStore() {
  const storedVal = browserStore(ADMIN_SECTION);

  if (isJSON(storedVal)) {
    const storeArr = JSON.parse(browserStore(ADMIN_SECTION));

    if (Array.isArray(storeArr) && storeArr.length) {
      return storeArr.reduce(
        (acc, sectionObj) => ({
          ...acc,
          sections: [...acc.sections, sectionObj.section],
          ...(sectionObj.page ? { page: sectionObj.page } : {}),
        }),
        { sections: [], page: '' },
      );
    }
  }

  return { sections: [], page: 'personal-information' };
}
