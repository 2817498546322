import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import cx from 'classnames';
import styled from 'styled-components';

import { useMounted, usePageLoading } from '@Base/hooks';
import { useTableState, DataTable } from '@Base/Tables';
import { DeleteButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';
import { TippyTruncate } from '@Base/Truncate';

import { retryableAPICall } from '@API/common-api-utils';
import { getLocation, deleteLocation } from '@API/LocationAPI';

import { locationTypes } from '@JS/model/form-schemas/LocationSchema';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { checkPermissions } from '@JS/auth/AuthUtils';

import LocationEditor from './LocationEditor';
import {
  EnhancedCard,
  EnhancedCardTitle,
  ClientAdminNoDataMessage,
  PageColumn,
  ClientAdminSlider,
  utils,
} from '../../Common';

async function deleteLoc(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteLocation(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0rem;
  }
`;

function LocationsAdmin({ isCreateMode, onClose, totalAccounts, activeAccountId, tablePrefs }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteLocId, setDeleteLocId] = useState();
  const [locationObj, setLocationObj] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [defaultLocs, setDefaultLocs] = useState({ EVENT: '', VACANCY: '' });

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getLocation());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          // Api doesn't sort so lets do it ourselves by Location Name
          const sortedLocations = resp?.sort((a, b) => a.name?.localeCompare(b.name));

          setTableData({
            data: sortedLocations,
            totalResults: resp.length,
          });

          if (resp.length) {
            let defEvtLocs = resp.filter(({ default: def, type }) => def && type === 'EVENT');
            let defVacLocs = resp.filter(({ default: def, type }) => def && type === 'VACANCY');

            if (!defEvtLocs.length) defEvtLocs = resp.filter(({ type }) => type === 'EVENT');
            if (!defVacLocs.length) defVacLocs = resp.filter(({ type }) => type === 'VACANCY');

            setDefaultLocs((prevProps) => ({
              ...prevProps,
              EVENT: defEvtLocs.length ? (defEvtLocs[0] || {}).id || '' : '',
              VACANCY: defVacLocs.length ? (defVacLocs[0] || {}).id || '' : '',
            }));
          }

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  const columns = [
    {
      Header: 'Type',
      id: 'locationType',
      headerClassName: 'text-center',
      width: 100,
      className: 'text-center',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        const type = locationTypes.find(({ value }) => value === original.type);
        return type.label;
      },
    },
    {
      Header: 'Default',
      id: 'defaultLocation',
      headerClassName: 'text-center',
      width: 90,
      className: 'text-center',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, type } = original;
        if (defaultLocs[type] !== id) return null;
        return <div className="faux-radio my-0 mx-auto" />;
      },
    },
    {
      Header: 'Location Name',
      id: 'locationName',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name, accountId, readOnly } = original;
        if (checkPermissions(['admin:locations:update']) && utils.isEditable(accountId, activeAccountId, readOnly)) {
          return (
            <a
              href="#locationName"
              onClick={(e) => {
                e.preventDefault();
                setLocationObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }
        return name;
      },
    },
    {
      Header: 'Location Details',
      id: 'locationDetails',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        const {
          // eslint-disable-next-line react/prop-types
          location: { formattedAddress },
          // eslint-disable-next-line react/prop-types
          phoneNumber,
          // eslint-disable-next-line react/prop-types
          readOnly,
          // eslint-disable-next-line react/prop-types
          accountId,
        } = original;
        const displayValue = phoneNumber || formattedAddress || '';

        if (checkPermissions(['admin:locations:update']) && utils.isEditable(accountId, activeAccountId, readOnly)) {
          return (
            <a
              href="#locationDetails"
              onClick={(e) => {
                e.preventDefault();
                setLocationObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              <TippyTruncate>{displayValue}</TippyTruncate>
            </a>
          );
        }

        return <TippyTruncate>{displayValue}</TippyTruncate>;
      },
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          let { sharedWith = [] } = original;

          // eslint-disable-next-line react/prop-types
          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccountId);

          if (!sharedWith.length) return null;

          if (sharedWith.length === totalAccounts - 1) {
            return 'ALL';
          }

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
      {
        Header: 'ACCOUNT',
        id: 'accountName',
        accessor: (r) => r.accountName,
        width: 125,
      },
    );
  }

  if (checkPermissions(['admin:locations:delete'])) {
    columns.push({
      id: 'action',
      width: 63,
      className: 'action-cell',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, accountId, readOnly } = original;

        const editable = utils.isEditable(accountId, activeAccountId, readOnly);
        const deletable = utils.isDeletable(accountId, activeAccountId);

        return (
          <Tippy
            content="Only the owner can delete this"
            delay={[1000, null]}
            disabled={editable && deletable}
            maxWidth="150px"
            theme="ats"
          >
            <div>
              <DeleteButton
                action={() => {
                  setDeleteLocId(id);
                  setShowConfirm(true);
                }}
                className="btn-sm xs"
                disabled={!editable || !deletable}
                floatRight={false}
                iconOnly
                label="Delete Location"
              />
            </div>
          </Tippy>
        );
      },
    });
  }

  return (
    <>
      <PageColumn className={cx({ 'h-scroll-table': tableState.data.length })} state={pageState}>
        <EnhancedCard>
          <EnhancedCardTitle title="Location Settings" subtitle="Create and update your branch locations" />
          {!tableState.data.length ? (
            <ClientAdminNoDataMessage
              btnProps={{
                onClick: () => setIsSliderOpen(true),
                label: 'Create a location',
              }}
              message="Create a location to use in your workflows"
              title="You currently have no Locations set"
            />
          ) : (
            <StyledTable
              className="mt-3"
              columns={columns}
              data={tableState.data}
              errorText="There has been an error loading locations, please try again later"
              hasSelectColumn={false}
              id="locations-admin"
              // totalPages={tableState.totalPages}
              isLoading={tableState.isLoading}
              isRejected={tableState.isRejected}
              noDataText="You currently have no locations"
              pageSize={tableState.rowsPerPage}
              showPagination={false}
              totalResults={tableState.totalResults}
            />
          )}
        </EnhancedCard>
      </PageColumn>
      <ClientAdminSlider
        closeSlider={handleCloseSlider}
        isSliderOpen={isSliderOpen}
        title={`${isEditing ? 'Edit' : 'Create'} Location`}
      >
        <LocationEditor
          data={isEditing ? locationObj : {}}
          isEditing={isEditing}
          onCancel={handleCloseSlider}
          onSave={(locObj, remainOpen) => {
            if (!remainOpen) {
              handleCloseSlider();
            }

            // reset default
            const currentData = [...tableState.data].map((loc) => ({ ...loc, default: false }));

            const updatedArr = isEditing
              ? updateObjInArray(currentData, locObj, locObj.id)
              : addObjToArray(currentData, locObj);

            if (updatedArr.length) {
              if (isEditing && locObj.default && locObj.type !== 'ALL') {
                setDefaultLocs({ ...defaultLocs, [locObj.type]: locObj.id });
              }

              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
            }
          }}
        />
      </ClientAdminSlider>
      <Confirmation
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          deleteLoc(
            deleteLocId,
            () => {
              toast.success('Location successfully deleted');

              const updatedArr = deleteObjFromArray(tableState.data, deleteLocId);

              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
            },
            () => {
              toast.error('Error deleting location');
            },
          );
          setShowConfirm(false);
        }}
        content="Are you sure you want to delete this location?"
        show={showConfirm}
      />
    </>
  );
}

LocationsAdmin.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  totalAccounts: PropTypes.number,
  activeAccountId: PropTypes.string,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

LocationsAdmin.defaultProps = {
  isCreateMode: false,
  totalAccounts: 0,
  activeAccountId: null,
  onClose: () => {},
  tablePrefs: {
    pageSize: 25,
  },
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
      },
    },
  } = state;
  return { tablePrefs, totalAccounts: accountAccess.length, activeAccountId };
}

export default connect(mapStateToProps)(LocationsAdmin);
