import React, { useState } from 'react';
import { Star, Award, ChevronDown } from 'lucide-react';
import { Col, Row } from 'reactstrap';
import FeedbackWidget from '@Base/Feedback/FeedbackWidget';
import TextToSpeechPlayer from '@Base/AI/TextToSpeechPlayer';

const AISummarySection = ({ applicant }) => {
  const [showAllSkills, setShowAllSkills] = useState(false);

  // Function to convert months to years (rounded to nearest 0.5)
  const monthsToYears = (months) => {
    const years = months / 12;
    return Math.round(years * 2) / 2; // Rounds to nearest 0.5
  };

  // Map and sort skills
  const allSkills =
    applicant.skills
      ?.map((skill) => ({
        name: skill.name,
        years: monthsToYears(skill.experienceMonths),
      }))
      .sort((a, b) => b.years - a.years) || [];

  return (
    <div className="app-summary-section">
      {/* Summary Header */}
      <div className="app-summary-section__header">
        <h4 className="app-summary-section__title">Candidate Summary</h4>
        <span className="app-summary-section__ai-badge">AI Generated</span>
        <TextToSpeechPlayer text={applicant.candidateSummary} />
      </div>

      {/* Summary Text */}
      <p className="app-summary-section__text">{applicant.candidateSummary}</p>

      {allSkills.length > 0 && (
        <div className="app-summary-section__skills">
          <div className="app-summary-section__section-header">
            <Star className="app-summary-section__icon" size={16} />
            <span>Skills</span>
          </div>
          <div className="app-summary-section__pills">
            {(showAllSkills ? allSkills : allSkills.slice(0, 5)).map((skill) => (
              <span key={skill.name} className="app-summary-section__skill-pill">
                {skill.name}
                <span className="app-summary-section__year-badge">{skill.years}yr</span>
              </span>
            ))}
          </div>
          {allSkills.length > 5 && (
            <button className="app-summary-section__show-more" onClick={() => setShowAllSkills(!showAllSkills)}>
              {showAllSkills ? 'Show Less' : `Show ${allSkills.length - 5} More Skills`}
              <ChevronDown className={showAllSkills ? 'rotate-180' : ''} size={16} />
            </button>
          )}
        </div>
      )}

      {applicant.strengths && applicant.strengths.length > 0 && (
        <div className="app-summary-section__strengths">
          <div className="app-summary-section__section-header">
            <Award className="app-summary-section__icon" size={16} />
            <span>Key Strengths</span>
          </div>
          <div className="app-summary-section__pills">
            {applicant.strengths?.map((strength) => (
              <span key={strength} className="app-summary-section__strength-pill">
                {strength}
              </span>
            ))}
          </div>
        </div>
      )}
      <Row style={{ marginTop: '10px' }}>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FeedbackWidget
            size="md"
            uniqueId={applicant.id}
            feature="AI_CV_SUMMARY"
            label="Let us know your feedback"
            txtStyle={{
              fontSize: '13px',
              color: '#9299a8', // slightly lighter
              marginRight: '12px',
              fontWeight: 500, // medium weight
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AISummarySection;
