import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { useFormKeypress, useMounted } from '@Base/hooks';
import { EditButton, CreateButton, CancelButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';
import { Loader } from '@Base/Loading';

import { requestStatuses } from '@JS/constants/requestStatuses';
import { trimFormData } from '@JS/utils/general-utils';
import validation, { mapErrors } from '@JS/utils/validation';
import { addS4User, removeS4User, addSiteMap } from '@JS/actions/s4Actions';

import { retryableAPICall } from '@API/common-api-utils';
import {
  getLinkedAccount,
  unlinkAccount,
  linkAccount,
  updateLinkedAccount,
  getSiteMapping,
  updateSiteMapping,
} from '@API/Integrations/S4API';

import S4SiteMapping from './S4SiteMapping';
import { EnhancedCard, IntegrationInput, EnhancedCardTitle, PageColumn } from '../../Common';

async function accountProxy(login, password, organisationId, opts = {}) {
  const { edit = false, unlink = false, advancedSettings = {} } = opts;
  let resp;

  if (login && password && organisationId) {
    if (edit) {
      resp = await retryableAPICall(() => updateLinkedAccount(login, password, organisationId, advancedSettings));
    } else {
      resp = await retryableAPICall(() => linkAccount(login, password, organisationId, advancedSettings));
    }
  } else if (unlink) {
    resp = await retryableAPICall(() => unlinkAccount());
  } else {
    resp = await retryableAPICall(() => getLinkedAccount());
  }

  return resp;
}

async function siteMappingProxy(onSuccess = () => {}, onError = () => {}, mapObj = {}) {
  let resp;

  if (Object.keys(mapObj).length) {
    resp = await retryableAPICall(() => updateSiteMapping(mapObj));
  } else {
    resp = await retryableAPICall(() => getSiteMapping());
  }

  if (typeof resp === 'string' && resp.length) {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error('Error fetching site mappings. Please try again later or contact support');
    }

    onError();
  } else {
    onSuccess(resp);
  }
}

// const advancedSettingsDefaultVals = cbInputConfig.reduce((acc, { id }) => ({ ...acc, [id]: true }), {});

function S4Admin({
  user,
  connected,
  accountAccess,
  accountIdToSiteId: accSiteMap,
  setS4User,
  deleteS4User,
  setSiteMap,
}) {
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [siteIds, setSiteIds] = useState([]);
  const [siteIdsLoading, setSiteIdsLoading] = useState(true);

  useEffect(() => {
    setFormData({
      ...user,
      password: '',
      // ...({ ...advancedSettingsDefaultVals, ...advancedSettings }),
    });

    if (connected) {
      setIsAuthorised(true);

      siteMappingProxy(
        (resp) => {
          setSiteIds(resp);
          setSiteIdsLoading(false);
        },
        () => setSiteIdsLoading(false),
      );
    }
  }, [connected, user]);

  useEffect(() => {
    if (isMounted()) {
      const fetchData = async () => {
        const resp = await accountProxy();

        if (typeof resp === 'string') {
          if (resp !== 'NOT_FOUND_ERROR') {
            toast.error('Error fetching account information. Please try again later or contact support');
          }
        } else {
          const { login, organisationId, accountIdToSiteId } = resp;
          setS4User(login, organisationId, accountIdToSiteId);
        }
      };
      if (!connected) fetchData();
    }
  }, [connected, isMounted, setS4User]);

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  async function handleSave(editCreds, unlink) {
    setIsSaving(true);

    const trimmedData = trimFormData(formData);

    const errObj = validation(
      [
        { id: 'login', required: true },
        { id: 'password', required: true },
        { id: 'organisationId', required: true },
      ],
      trimmedData,
    );

    const { messages, hasErrors } = mapErrors(errObj);
    if (!unlink) setErrors(messages);

    if (!hasErrors || unlink) {
      const {
        login,
        password,
        organisationId,
        // ...rest
      } = trimmedData;

      const resp = await accountProxy(login, password, organisationId, {
        edit: editCreds,
        unlink,
      });

      if (typeof resp === 'string' && resp.length) {
        let errMsg = 'Error submitting data. Please try again later or contact support';

        if (resp === requestStatuses.INVALID_CREDENTIALS) {
          errMsg = 'Error submitting data. Invalid Login or Password. Please correct and try again.';
        }

        toast.error(errMsg);
      } else if (typeof resp === 'string' && !resp.length) {
        const successMsg = `Account ${unlink ? 'Unlinked' : 'Linked'} successfully`;

        toast.success(successMsg);
        setIsAuthorised(!unlink);
        setIsEditing(false);

        if (unlink) {
          deleteS4User();
        } else {
          setS4User(login, organisationId);
        }
      }
    }

    setIsSaving(false);
  }

  return (
    <>
      <PageColumn permissions={['s4:admin']}>
        <EnhancedCard>
          <EnhancedCardTitle
            title="S4 Integration"
            subtitle="Manage your connection details to pass candidates to S4"
          />
          <Form innerRef={formRef}>
            <IntegrationInput
              label="Login"
              id="login"
              value={formData.login || ''}
              onChange={(val) => handleChange('login', val)}
              error={errors.login}
              isAuthorised={isAuthorised}
              isEditing={isEditing}
            />
            <IntegrationInput
              label="Password"
              id="password"
              type="password"
              value={formData.password || ''}
              onChange={(val) => handleChange('password', val)}
              error={errors.password}
              isAuthorised={isAuthorised}
              isEditing={isEditing}
              hideValue
            />
            <IntegrationInput
              label="Organisation ID"
              id="organisationId"
              value={formData.organisationId || ''}
              onChange={(val) => handleChange('organisationId', val)}
              error={errors.organisationId}
              isAuthorised={isAuthorised}
              isEditing={isEditing}
            />
            {isAuthorised && !isEditing ? (
              <Fragment>
                <EditButton
                  className="mt-2"
                  floatRight={false}
                  label="Edit Credentials"
                  action={() => setIsEditing(true)}
                />
                <CancelButton
                  className="mt-2"
                  label="Unlink Account"
                  isLoading={isSaving}
                  disabled={isSaving}
                  action={(e) => {
                    e.preventDefault();
                    setIsConfirmOpen(true);
                  }}
                />
              </Fragment>
            ) : (
              <CreateButton
                className="mt-2"
                label={isSaving ? 'Authenticating...' : 'Link Account'}
                isLoading={isSaving}
                disabled={isSaving}
                floatRight={false}
                action={(e) => {
                  e.preventDefault();
                  handleSave(isEditing);
                }}
              />
            )}
            {isEditing && (
              <CancelButton
                className="mt-2 ms-2"
                isLoading={isSaving}
                disabled={isSaving}
                floatRight={false}
                action={() => {
                  setIsEditing(false);
                  setErrors({});
                }}
              />
            )}
          </Form>
          {connected && (
            <Fragment>
              <hr />
              {siteIdsLoading ? (
                <Loader />
              ) : (
                <S4SiteMapping
                  accountAccess={accountAccess}
                  siteIds={siteIds}
                  accountIdToSiteId={accSiteMap}
                  onUpdate={(mappingData, onComplete) => {
                    if (Object.keys(mappingData).length) {
                      siteMappingProxy(
                        () => {
                          toast.success('Site Mapping updated successfully');
                          onComplete();
                          setSiteMap(mappingData);
                        },
                        () => {
                          onComplete();
                        },
                        mappingData,
                      );
                    }
                  }}
                />
              )}
            </Fragment>
          )}
        </EnhancedCard>
      </PageColumn>
      <Confirmation
        show={isConfirmOpen}
        content="Are you sure you want to Unlink account?"
        cancelCallback={() => setIsConfirmOpen(false)}
        confirmCallback={() => {
          handleSave(false, true);
          setIsConfirmOpen(false);
        }}
      />
    </>
  );
}

S4Admin.propTypes = {
  user: PropTypes.shape(),
  connected: PropTypes.bool,
  accountAccess: PropTypes.arrayOf(PropTypes.shape()),
  accountIdToSiteId: PropTypes.shape(),
  setS4User: PropTypes.func,
  deleteS4User: PropTypes.func,
  setSiteMap: PropTypes.func,
};

S4Admin.defaultProps = {
  user: {
    login: '',
    organisationId: '',
  },
  connected: false,
  accountAccess: [],
  accountIdToSiteId: {},
  setS4User: () => {},
  deleteS4User: () => {},
  setSiteMap: () => {},
};

function mapStateToProps(state) {
  const {
    s4: { user, connected, accountIdToSiteId },
    userData: {
      userDetails: {
        data: { accountAccess },
      },
    },
  } = state;

  return {
    user,
    connected,
    accountAccess,
    accountIdToSiteId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setS4User: (login, organisationId, accountIdToSiteId) => {
      dispatch(addS4User(login, organisationId, accountIdToSiteId));
    },
    deleteS4User: () => {
      dispatch(removeS4User());
    },
    setSiteMap: (accountIdToSiteId) => {
      dispatch(addSiteMap(accountIdToSiteId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(S4Admin);
