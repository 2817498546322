import React, { useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '@API/common-api-utils';
import { saveCredentials, getCredentials } from '@API/Integrations/AccessAPI';
import { useMounted } from '@Base/hooks';
import { requestStatuses } from '@JS/constants/requestStatuses';
import { CancelButton, CreateButton, EditButton } from '@Base/Buttons';

import { EnhancedCard, EnhancedCardTitle, PageColumn, IntegrationInput } from '../../Common';

const initialState = {
  isAuthorised: true,
  isEditing: false,
  isLoading: true,
  isPromptOpen: false,
  isSaving: false,
  originalToken: '',
  originalUsername: '',
  token: '',
  username: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_USERNAME':
      return { ...state, username: action.val };
    case 'CHANGE_PASSWORD':
      return { ...state, password: action.val };
    case 'CHANGE_TOKEN':
      return { ...state, token: action.val };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };
    case 'SET_CREDENTIALS':
      return {
        ...state,
        originalToken: action.payload.apiKey,
        originalUsername: action.payload.organisationId,
        token: action.payload.apiKey,
        username: action.payload.organisationId,
      };
    case 'ADD_CREDENTIALS':
      return {
        ...state,
        originalToken: state.token,
        originalUsername: state.username,
        token: state.token,
        username: state.username,
      };
    case 'SET_LOADING_COMPLETE':
      return { ...state, isLoading: false };
    case 'SET_IS_EDITING':
      return { ...state, isEditing: true };
    case 'SET_IS_EDITING_CANCELLED':
      return { ...state, isEditing: false };
    default:
      return state;
  }
};

function AccessAdmin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useMounted();

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SET_IS_LOADING' });

      try {
        const resp = await retryableAPICall(() => getCredentials());

        if (typeof resp === 'string' && resp === requestStatuses.PERMISSION_DENIED) {
          toast.error('You do not have the required permissions. Please contact support');
        } else if (typeof resp === 'string') {
          toast.error('Failed to load credentials. Please try again later or contact support');
        } else {
          dispatch({ type: 'SET_CREDENTIALS', payload: resp });
        }
      } catch (error) {
        toast.error('Failed to load credentials. Please try again later or contact support');
      }
      dispatch({ type: 'SET_LOADING_COMPLETE' });
    };
    fetchData();
  }, [isMounted]);

  const handleSave = async () => {
    dispatch({ type: 'SET_IS_LOADING' });
    try {
      const resp = await retryableAPICall(() => saveCredentials(state.username, state.token, state.password));

      if (isMounted) {
        if (typeof resp === 'string' && resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          toast.error('A user for this account already exists. Please contact support for assistance.');
        } else if (typeof resp === 'string') {
          toast.error('Failed to add credentials. Please try again later or contact support');
        } else {
          dispatch({
            type: 'ADD_CREDENTIALS',
          });
        }
      }
    } catch (error) {
      toast.error('Failed to add credentials. Please try again later or contact support');
    } finally {
      if (isMounted) {
        dispatch({ type: 'SET_LOADING_COMPLETE' });
      }
    }
  };

  return (
    <PageColumn
      state={{
        isPending: state.isLoading,
      }}
      permission={['admin:accessgrp']}
    >
      <EnhancedCard>
        <EnhancedCardTitle
          title="Access Group Integration"
          subtitle="Manage your connection details to pass candidates to the Access Group"
        />
        <IntegrationInput
          id="username"
          isAuthorised={state.isAuthorised}
          isEditing={state.isEditing}
          label="Organisation Id"
          onChange={(val) => {
            dispatch({
              type: 'CHANGE_USERNAME',
              val,
            });
          }}
          value={state.username || ''}
        />
        <IntegrationInput
          hideValue
          id="token"
          isAuthorised={state.isAuthorised}
          isEditing={state.isEditing}
          label="API Token"
          onChange={(val) => {
            dispatch({
              type: 'CHANGE_TOKEN',
              val,
            });
          }}
          type="password"
          value={state.token || ''}
        />
        {state.isEditing ? (
          <>
            <CreateButton
              action={(e) => {
                e.preventDefault();
                handleSave();
              }}
              className="mt-2"
              disabled={state.isSaving}
              floatRight={false}
              isLoading={state.isSaving}
              label={state.isSaving ? 'Authenticating...' : 'Save'}
            />
            <CancelButton
              action={() => {
                dispatch({
                  type: 'SET_IS_EDITING_CANCELLED',
                });
              }}
              className="mt-2 ms-2"
              disabled={state.isSaving}
              floatRight={false}
              isLoading={state.isSaving}
            />
          </>
        ) : (
          <EditButton
            action={() =>
              dispatch({
                type: 'SET_IS_EDITING',
              })
            }
            className="mt-2"
            floatRight={false}
            label="Edit Credentials"
          />
        )}
      </EnhancedCard>
    </PageColumn>
  );
}

AccessAdmin.propTypes = {};

AccessAdmin.defaultProps = {};

export default AccessAdmin;
