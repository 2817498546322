import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useMounted, usePageLoading } from '@Base/hooks';
import { DataTable, useTableState } from '@Base/Tables';
import { ActionButton } from '@Base/Buttons';
import { Prompt } from '@Base/Modal';

import { checkPermissions, doRenewSession } from '@JS/auth/AuthUtils';
import { addAccount, updateAccount, deleteAccount as removeAccount } from '@JS/actions/userActions';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';

import { listAccounts, deleteAccount as deleteAcc } from '@API/AccountAPI';
import { retryableAPICall } from '@API/common-api-utils';

import AccountAdminEditor from './AccountAdminEditor';
import { EnhancedCard, EnhancedCardTitle, ClientAdminNoDataMessage, PageColumn, ClientAdminSlider } from '../../Common';

async function deleteAccount(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteAcc(id));

    if (typeof resp === 'string' && resp.length) {
      onError(resp);
    } else {
      onSuccess();
    }
  }
}

function AccountAdmin({
  isCreateMode,
  onClose,
  // searchTerm,
  tablePrefs,
  addAcc,
  updateAcc,
  removeAcc,
  activeAccountId,
  setTitleSubItems,
}) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showPrompt, setShowPrompt] = useState(false);
  const [deleteAccountObj, setDeleteAccountObj] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  // const [alertContent, setAlertContent] = useState();
  const [actionMenuId, setActionMenuId] = useState('');
  const [accountObj, setAccountObj] = useState({});

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => listAccounts());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setTableData({
            data: resp,
            totalResults: resp.length,
          });

          const activeAccounts = resp.filter((acc) => acc.status !== 'PENDING');
          setTitleSubItems([{ title: 'Active accounts', content: activeAccounts?.length || 0 }]);

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  const columns = [
    {
      Header: 'Account Name',
      id: 'name',
      minWidth: 200,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name } = original;

        if (checkPermissions(['admin:usermanagement:update'])) {
          return (
            <a
              href="#accountDetails"
              onClick={(e) => {
                e.preventDefault();
                setAccountObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Unique Apps',
      id: 'vacancySettings.restrictAppsByAccount',
      accessor: (r) => {
        return r.vacancySettings?.restrictAppsByAccount ? 'Yes' : 'No';
      },
      width: 50,
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (r) => r.status,
      width: 125,
    },
    {
      Header: 'CREATED',
      id: 'createdDateTime',
      accessor: (r) => moment(r.createdDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'),
      width: 125,
    },
  ];

  if (checkPermissions(['admin:usermanagement:update', 'admin:usermanagement:delete'])) {
    columns.push({
      id: 'action',
      width: 63,
      className: 'action-cell',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, name } = original;

        const noDelete = activeAccountId === id || tableState.totalResults === 1;

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:usermanagement:update'],
            action: () => {
              setAccountObj(original);
              setIsEditing(true);
              setIsSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:usermanagement:delete'],
            isDisabled: noDelete,
            tooltipContent: 'You cannot delete active account',
            action: () => {
              setDeleteAccountObj({ id, name });
              setActionMenuId('');
              setShowPrompt(true);
            },
          },
        ];

        return (
          <ActionButton
            isOpen={actionMenuId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
          />
        );
      },
    });
  }

  return (
    <>
      <PageColumn className={cx({ 'h-scroll-table': tableState.data.length })} state={pageState}>
        <EnhancedCard className="mb-4">
          <EnhancedCardTitle
            title="Account Management"
            subtitle="Create new accounts or update existing account details"
          />
          {!tableState.data.length ? (
            <ClientAdminNoDataMessage
              btnProps={{
                onClick: () => setIsSliderOpen(true),
                label: 'Create an Account',
              }}
              message=""
              title="You currently have no accounts set up"
            />
          ) : (
            <>
              <DataTable
                className="mt-3"
                columns={columns}
                data={tableState.data}
                errorText="There has been an error loading accounts, please try again later"
                hasSelectColumn={false}
                id="account-admin"
                isLoading={tableState.isLoading}
                // totalPages={tableState.totalPages}
                isRejected={tableState.isRejected}
                noDataText="You currently have no accounts"
                pageSize={tableState.rowsPerPage}
                rowHeight={53}
                showPagination={false}
                totalResults={tableState.totalResults}
              />
              {/* <InfoAlert
              content={alertContent}
              onClose={() => setAlertContent()}
            /> */}
            </>
          )}
        </EnhancedCard>
      </PageColumn>
      <ClientAdminSlider
        closeSlider={handleCloseSlider}
        isSliderOpen={isSliderOpen}
        title={`${isEditing ? 'Edit' : 'Create'} Account`}
      >
        <AccountAdminEditor
          data={isEditing ? accountObj : {}}
          isEditing={isEditing}
          onCancel={handleCloseSlider}
          onSave={(accObj) => {
            handleCloseSlider();

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, accObj, accObj.id)
              : addObjToArray(tableState.data, accObj);

            setTableData({
              data: updatedArr,
              totalResults: updatedArr.length,
            });

            // update redux
            if (isEditing) {
              updateAcc({
                accountId: accObj.id,
                accountName: accObj.name,
                restrictAppsPerAccount: accObj.restrictAppsPerAccount,
              });
            } else {
              addAcc({
                accountId: accObj.id,
                accountName: accObj.name,
                restrictAppsPerAccount: accObj.restrictAppsPerAccount,
              });
              doRenewSession();
            }
          }}
        />
      </ClientAdminSlider>
      <Prompt
        closeOnOkay={false}
        content={
          <>
            {deleteAccountObj?.name && <p className="mb-3">{`Account: ${deleteAccountObj.name}`}</p>}
            <p className="mb-3">
              Deleting this account will make all vacancies and candidates created under this account inaccessible. This
              is not reversible and all data under this account will be lost.
            </p>
          </>
        }
        isOpen={showPrompt}
        nameTitle="Type 'DELETE' to confirm your decision"
        noCacheValue
        onCancel={() => setShowPrompt(false)}
        onOkay={(val) => {
          const trimmedVal = (val || '').trim();

          if (trimmedVal.length && trimmedVal === 'DELETE') {
            deleteAccount(
              deleteAccountObj.id,
              () => {
                toast.success('Account successfully deleted');

                const updatedArr = deleteObjFromArray(tableState.data, deleteAccountObj.id);

                setTableData({
                  data: updatedArr,
                  totalResults: updatedArr.length,
                });

                removeAcc(deleteAccountObj.id);
                doRenewSession();
              },
              (resp) => {
                let reasonStr = '';
                if (resp === 'NOT_FOUND_ERROR') {
                  reasonStr = ' Could not find account.';
                } else if (resp === 'PERMISSION_DENIED') {
                  reasonStr = ' You cannot delete the only account.';
                } else if (resp === 'PERMISSION_DENIED') {
                  reasonStr = ' You do not have permission to delete this account.';
                }
                toast.error(`Error deleting account.${reasonStr}`);
              },
            );

            setShowPrompt(false);
            setDeleteAccountObj();
          }
        }}
        title="Are you sure you want to delete this account?"
      />
    </>
  );
}

AccountAdmin.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  // searchTerm: PropTypes.string,
  addAcc: PropTypes.func,
  updateAcc: PropTypes.func,
  removeAcc: PropTypes.func,
  activeAccountId: PropTypes.string,
  setTitleSubItems: PropTypes.func,
};

AccountAdmin.defaultProps = {
  isCreateMode: false,
  onClose: () => {},
  tablePrefs: {
    pageSize: 25,
  },
  // searchTerm: null,
  addAcc: () => {},
  updateAcc: () => {},
  removeAcc: () => {},
  activeAccountId: null,
  setTitleSubItems: () => {},
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        // data: {
        //   accountAccess = [],
        // },
        activeAccountId,
      },
    },
  } = state;

  return {
    tablePrefs,
    // totalAccounts: accountAccess.length,
    activeAccountId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAcc: (accObj) => {
      dispatch(addAccount(accObj));
    },
    updateAcc: (accObj) => {
      dispatch(updateAccount(accObj));
    },
    removeAcc: (accountId) => {
      dispatch(removeAccount(accountId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountAdmin);
