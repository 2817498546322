// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import IconSVG from '@Base/SVG/IconSVG';
import iconLookup from './timelineIcons';
import { TextButton } from '@Base/Buttons';

function doAutoShow(type) {
  switch (type) {
    case 'NOTE_ADDED':
    case 'NOTE_DELETED':
    case 'NOTE_EDITED':
    case 'SMS_SENT':
    case 'SMS_RECEIVED':
    case 'NOTE':
      return true;
    default:
      return false;
  }
}

function TimelineEntry({ type, title, subtitle, createdDateTime, content }) {
  const [isContentOpen, setIsContentOpen] = useState(false);

  useEffect(() => {
    setIsContentOpen(doAutoShow(type));
  }, [type]);

  const icon = iconLookup[type];

  let parsedContent;

  if (type === 'NOTE_ADDED' && content) {
    parsedContent = content.replace(/@\[(.*?)]/g, '<strong>@$1</strong>').replace(/#\[(.*?)]/g, '<strong>#$1</strong>');
  } else {
    parsedContent = content;
  }
  return (
    <section className="timeline-entry">
      <div className="tl-icon">{icon && <IconSVG {...icon} width="1.25em" />}</div>
      <div className={cx('tl-container', { 'has-content': !!parsedContent })}>
        <div className="tl-entry-data">
          <time dateTime={createdDateTime}>{createdDateTime}</time>
          <p className="tl-title">{title}</p>
          {subtitle && <p className="tl-subtitle">{subtitle}</p>}
        </div>
        {parsedContent && (
          <div className="tl-content">
            <TextButton
              action={() => setIsContentOpen(!isContentOpen)}
              className="btn-outline tl-toggle"
              label={isContentOpen ? 'Hide' : 'View'}
              size="sm"
            />
            {/* eslint-disable-next-line react/no-danger */}
            <div className={cx({ 'is-open': isContentOpen })} dangerouslySetInnerHTML={{ __html: parsedContent }} />
          </div>
        )}
      </div>
    </section>
  );
}

TimelineEntry.propTypes = {
  content: PropTypes.string,
  createdDateTime: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

TimelineEntry.defaultProps = {
  content: null,
  createdDateTime: null,
  subtitle: null,
  title: null,
  type: null,
};

export default TimelineEntry;
