import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { findUsersForAccount } from '@API/AccountAPI';

function UserSelect({ activeAccountId, currentAssignment, onChange }) {
  const [availableUsers, setAvailableUsers] = useState([]);

  useEffect(() => {
    async function getUsers() {
      try {
        const result = await findUsersForAccount(null, activeAccountId);
        setAvailableUsers(result.map((val) => ({ value: val.id, label: val.name })));
      } catch (error) {
        toast.error('Could not load users for assigning vacancy to. Please try again later');
      }
    }

    getUsers();
  }, [activeAccountId]);

  return (
    <Select
      isMulti
      classNamePrefix="react-select"
      className="user-select w-100"
      value={currentAssignment}
      onChange={(selectedOptions) => {
        onChange(selectedOptions);
      }}
      options={availableUsers}
      placeholder="Assign to..."
    />
  );
}

UserSelect.propTypes = {
  activeAccountId: PropTypes.string.isRequired,
  currentAssignment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

UserSelect.defaultProps = {
  currentAssignment: [],
  onChange: () => {},
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: { activeAccountId },
    },
  } = state;

  return { activeAccountId };
}

export default connect(mapStateToProps)(UserSelect);
