// components/DashboardHeader.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { TopDetailBar } from '@Base/DetailBar';
import { EditButton, CreateButton, TextButton } from '@Base/Buttons';
import { TableSearchBar } from '@Base/Forms/Custom/TableSearchBar';
import Can from '@Base/RBAC/Can/Can';

import { reSyncAccounts } from '@API/AccountAPI';

import FlexEnd from './FlexEnd';

const RestrictedCreateButton = Can(CreateButton);
const RestrictedTextButton = Can(TextButton);
const RestrictedTableSearchBar = Can(TableSearchBar);

const DashboardHeader = ({
  pageTitle,
  titleSubItems,
  activeTab,
  setIsCreateMode,
  isCreateMode,
  setSearchTerm,
  setIsImportModalOpen,
  allowAccountSwitch,
  accountSwitchSections,
  activeAccountName,
  setAccountSwitchOpen,
  userType,
}) => {
  const [isReSyncing, setIsResyncing] = useState(false);

  async function handleReSyncAccounts() {
    setIsResyncing(true);
    try {
      await reSyncAccounts(email);
      toast.success('Successfully reSynced accounts. Please refresh');
    } catch (e) {
      toast.error('Could not reSync accounts for your user');
    } finally {
      setIsResyncing(false);
    }
  }

  const renderHeaderActions = () => {
    switch (activeTab) {
      case 'company-profile':
        return (
          <FlexEnd>
            <EditButton action={() => setIsCreateMode(true)} floatRight={false} label="Edit Profile" />
          </FlexEnd>
        );

      case 'forms':
        return (
          <FlexEnd>
            <RestrictedCreateButton
              action={() => setIsImportModalOpen(true)}
              className="me-2"
              floatRight={false}
              label="Import a new Form"
              permissions={['talentfunnel:admin']}
            />
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new Form"
              permissions={['admin:forms:create']}
            />
          </FlexEnd>
        );

      case 'edocs':
        return (
          <FlexEnd>
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new eDoc"
              permissions={['admin:edoc:create']}
            />
          </FlexEnd>
        );

      case 'funnels':
        return (
          <FlexEnd>
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new Funnel"
              permissions={['admin:funnel:create']}
            />
          </FlexEnd>
        );

      case 'email-templates':
      case 'vacancy-templates':
        return (
          <RestrictedTableSearchBar
            minWidth="300px"
            onSearch={(val) => setSearchTerm(val)}
            permissions={['admin:emails:read']}
            placeholder="Search Templates"
          >
            <RestrictedCreateButton
              action={() => setIsCreateMode(!isCreateMode)}
              label={`Create ${activeTab === 'email-templates' ? 'Email' : 'Vacancy'} Template`}
              permissions={activeTab === 'email-templates' ? ['admin:emails:create'] : []}
            />
          </RestrictedTableSearchBar>
        );

      case 'locations':
        return (
          <FlexEnd>
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new Location"
              permissions={['admin:locations:create']}
            />
          </FlexEnd>
        );

      case 'workflows':
        return (
          <FlexEnd>
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new Workflow"
              permissions={['admin:workflow:create']}
            />
          </FlexEnd>
        );

      case 'accounts':
        if (userType === 'JOBSITE_ATS') return null;
        return (
          <FlexEnd>
            <RestrictedTextButton
              action={() => handleReSyncAccounts()}
              className="me-1"
              disabled={isReSyncing}
              floatRight={false}
              label={isReSyncing ? 'ReSyncing...' : 'ReSync Accounts'}
              permissions={['talentfunnel:admin']}
            />
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new Account"
              permissions={['admin:usermanagement:create']}
            />
          </FlexEnd>
        );

      case 'users':
        return (
          <FlexEnd>
            <TableSearchBar
              className="me-2"
              minWidth="50%"
              onSearch={(val) => setSearchTerm(val)}
              placeholder="Search Users"
            />
            <RestrictedCreateButton
              action={() => setIsCreateMode(true)}
              floatRight={false}
              label="Create a new User"
              permissions={['admin:usermanagement:create']}
            />
          </FlexEnd>
        );

      default:
        return null;
    }
  };

  const renderAccountSwitch = () => {
    if (!allowAccountSwitch || !accountSwitchSections.includes(activeTab)) {
      return null;
    }

    return (
      <FlexEnd className="align-items-center mt-3">
        <p>
          {`Currently viewing ${pageTitle} in the ${activeAccountName} account `}
          <a
            href="#switch-account"
            onClick={(e) => {
              e.preventDefault();
              setAccountSwitchOpen(true);
            }}
          >
            Switch Account
          </a>
        </p>
      </FlexEnd>
    );
  };

  return (
    <TopDetailBar title={pageTitle} subItems={titleSubItems}>
      <div className="ms-auto">
        {renderHeaderActions()}
        {renderAccountSwitch()}
      </div>
    </TopDetailBar>
  );
};

DashboardHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  titleSubItems: PropTypes.arrayOf(PropTypes.shape({})),
  activeTab: PropTypes.string,
  setIsCreateMode: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool,
  setSearchTerm: PropTypes.func.isRequired,
  setIsImportModalOpen: PropTypes.func.isRequired,
  allowAccountSwitch: PropTypes.bool,
  accountSwitchSections: PropTypes.arrayOf(PropTypes.string),
  activeAccountName: PropTypes.string,
  setAccountSwitchOpen: PropTypes.func.isRequired,
  isReSyncing: PropTypes.bool,
  handleReSyncAccounts: PropTypes.func.isRequired,
  userType: PropTypes.string,
};

DashboardHeader.defaultProps = {
  titleSubItems: [],
  activeTab: '',
  isCreateMode: false,
  allowAccountSwitch: false,
  accountSwitchSections: [],
  activeAccountName: '',
  isReSyncing: false,
  userType: '',
};

export default DashboardHeader;
