import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '@API/common-api-utils';
import { searchQuestions } from '@API/FormsAPI/ATSFormsAPI';

const aiDecisionCategories = [
  { label: 'Application Questions', value: 'APPLICATION_QUESTIONS' },
  { label: 'CV Questions', value: 'CV_QUESTIONS' },
  { label: 'Onboarding Information', value: 'ONBOARDING' },
];
function AIDecisionCriteria({ option, setOption }) {
  return (
    <>
      <FormGroup>
        <Label for="decision-criteria" style={{ marginTop: '0px', marginBottom: '3px' }}>
          Enter in your own words what you'd like to make a decision on. This feature is still in beta and we recommend
          you only use it for non-critical decisions.
        </Label>
        <Input
          id="decision-criteria"
          className="mt-2"
          type="textarea"
          style={{ height: '120px' }}
          placeholder="Decision Criteria"
          value={option?.condition?.criteria}
          onChange={(e) => {
            setOption({ criteria: e.target.value, category: option?.condition?.category });
          }}
        />
      </FormGroup>
    </>
  );
}

export default AIDecisionCriteria;
