import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { retryableAPICall } from '@API/common-api-utils';
import { getVacancyCategories } from '@API/VacancyAPI';

let availableCategoryCache = [];

async function fetchCategories(onSuccess = () => {}) {
  if (!availableCategoryCache.length) {
    const resp = await retryableAPICall(() => getVacancyCategories());

    if (typeof resp === 'string') {
      toast.error('There has been a problem loading the categories for the filter');
    } else {
      const categoryOpts = resp.categories.map(({ id, label }) => ({ label, value: id }));
      onSuccess(categoryOpts);
      availableCategoryCache = [...categoryOpts];
    }
  } else {
    onSuccess(availableCategoryCache);
  }
}

function CategoryFilter({ onChange, value }) {
  const [availableCategories, setAvailableCategories] = useState([]);

  useEffect(() => {
    fetchCategories((opts) => {
      setAvailableCategories(opts);
    });
  }, []);

  return (
    <Select
      className="reporting-select reporting-filter"
      classNamePrefix="react-select"
      id="filter-category"
      isMulti
      onChange={(val) => {
        onChange(val);
      }}
      options={availableCategories}
      placeholder="Choose Role"
      value={value}
    />
  );
}

CategoryFilter.propTypes = {
  value: PropTypes.shape(),
  onChange: PropTypes.func,
};

CategoryFilter.defaultProps = {
  value: {},
  onChange: () => {},
};

export default CategoryFilter;
