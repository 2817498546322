import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'reactstrap';
import cx from 'classnames';
import Select from 'react-select';
import { FormGroup } from '../CommonComponents';
import { countryList } from '@JS/model/other/countries';

const inputs = [
  {
    id: 'buildingName',
    label: 'Building Name',
  },
  {
    id: 'addressLine1',
    label: 'Address Line 1',
    required: true,
  },
  {
    id: 'addressLine2',
    label: 'Address Line 2',
  },
  {
    id: 'addressLine3',
    label: 'Address Line 3',
  },
  {
    id: 'city',
    label: 'City',
    required: true,
  },
  {
    id: 'county',
    label: 'County',
  },
  {
    id: 'postCode',
    label: 'Postcode',
  },
  {
    id: 'country',
    label: 'Country',
    required: true,
  },
  {
    id: 'lat',
    label: 'Latitude',
    required: true,
    type: 'number',
  },
  {
    id: 'long',
    label: 'Longitude',
    required: true,
    type: 'number',
  },
];

function ManualAddress({ id, value, required, onChange, onToggle }) {
  const [displayInputs, setDisplayInputs] = useState(false);

  function handleChange(inpId, inpVal) {
    const updatedAddr = { ...value, [inpId]: inpVal };
    onChange(updatedAddr);
  }

  return (
    <>
      <Button
        className="px-0 float-end"
        color="link"
        onClick={() => {
          setDisplayInputs(!displayInputs);
          onToggle(!displayInputs);
        }}
        size="sm"
      >
        Manually edit address
      </Button>
      <div className={cx('manual-address', { 'd-none': !displayInputs })}>
        {inputs.map(({ label, id: inpId, required: req, type }) => {
          const idStr = `${id}-${inpId}`;
          const isRequired = required && req;

          return (
            <FormGroup
              key={idStr}
              className={`field field-string ${id} ${inpId}`}
              id={idStr}
              label={label}
              required={isRequired}
            >
              {inpId === 'country' ? (
                <Select
                  classNamePrefix="react-select"
                  id={idStr}
                  onChange={(country) => handleChange(inpId, country.value)}
                  options={countryList}
                  value={countryList.find(({ value: val }) => val === value[inpId])}
                />
              ) : (
                <Input
                  id={idStr}
                  onChange={(e) => handleChange(inpId, e.target.value)}
                  step="0.1"
                  type={type || 'text'}
                  value={value[inpId] || ''}
                />
              )}
            </FormGroup>
          );
        })}
      </div>
    </>
  );
}

ManualAddress.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onToggle: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.shape(),
};

ManualAddress.defaultProps = {
  onChange: () => {},
  onToggle: () => {},
  required: false,
  value: {},
};

export default ManualAddress;
