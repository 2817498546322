import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useMounted, usePageLoading } from '@Base/hooks';
import { DataTable, useTableState } from '@Base/Tables';
import { ActionButton } from '@Base/Buttons';
import { TippyTruncate } from '@Base/Truncate';
import { Confirmation } from '@Base/Modal';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '@JS/utils/arrayOfObjects';
import { retryableAPICall } from '@API/common-api-utils';
import { getAllWorkflows, deleteWorkflow } from '@API/AdvancedWorkflowAPI';
import Select from 'react-select';
import { checkPermissions } from '@JS/auth/AuthUtils';
import WorkflowCreatePanel from './WorkflowCreatePanel';
import WorkflowEditPanel from './WorkflowEditPanel';
import { Input } from 'reactstrap';
import { CreateButton } from '@Base/Buttons';
import Can from '@Base/RBAC/Can/Can';
import styled from 'styled-components';

import { EnhancedCard, ClientAdminNoDataMessage, PageColumn, utils } from '../../Common';

const RestrictedCreateButton = Can(CreateButton);

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0rem;
  }
`;

const SearchInput = styled(Input)`
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding-left: 1rem;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

async function deleteAction(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteWorkflow(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function AdvancedWorkflow({ totalAccounts, activeAccountId, tablePrefs }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionMenuId, setActionMenuId] = useState('');
  const [deleteObj, setDeleteObj] = useState();
  const [templateId, setTemplateId] = useState(null);
  const [workflowId, setWorkflowId] = useState(null);
  const [pageViewState, setPageViewState] = useState('view');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState({ value: 'LIVE', label: 'Live' });
  const [allDataLength, setAllDataLength] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const getSearchParams = () => new URLSearchParams(window.location.search);

  const updateURL = (mode, id = null) => {
    const params = getSearchParams();

    if (mode) {
      params.set('mode', mode);
    } else {
      params.delete('mode');
    }

    if (id) {
      params.set('id', id);
    } else {
      params.delete('id');
    }

    window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const openWorkflowEditor = (workflow) => {
    setWorkflowId(workflow.id);
    setTemplateId(null);
    setPageViewState('edit');
    updateURL('edit', workflow.id);
  };

  const createWorkflow = (templateId) => {
    setWorkflowId('new');
    setTemplateId(templateId);
    setPageViewState('edit');
    updateURL('edit', 'new');
  };

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getAllWorkflows(debouncedSearchTerm, selectedType.value));

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setTableData({
            data: resp,
            totalResults: resp.length,
          });
          setAllDataLength(resp.length);
          callback();
        }
      }
    },
    [debouncedSearchTerm, isMounted, setTableData, setTableRejected, selectedType],
  );

  useEffect(() => {
    const params = getSearchParams();
    const mode = params.get('mode');
    const id = params.get('id');

    if (mode === 'edit' && id) {
      setPageViewState('edit');
      setWorkflowId(id);
      setTemplateId(null);
    } else if (mode === 'create') {
      setPageViewState('create');
      setWorkflowId(null);
      setTemplateId(null);
    } else {
      setPageViewState('view');
      setWorkflowId(null);
      setTemplateId(null);
    }
  }, []);

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  const columns = [
    {
      Header: 'Workflow Name',
      id: 'actionName',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name } = original;

        if (checkPermissions(['admin:workflow:update'])) {
          return (
            <a
              href="#workflowDetails"
              onClick={(e) => {
                e.preventDefault();
                setWorkflowId(original.id);
                setTemplateId(null);
                setPageViewState('edit');
                updateURL('edit', original.id);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Status',
      id: 'status',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { status } = original;

        if (status === 'LIVE') {
          return 'Live';
        }
        return 'Draft';
      },
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        resizable: false,
        sortable: false,
        filterable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          let { sharedWith = [] } = original;

          // eslint-disable-next-line react/prop-types
          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccountId);

          if (!sharedWith.length) return null;

          if (sharedWith.length === totalAccounts - 1) {
            return 'All';
          }

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }

  if (checkPermissions(['admin:workflow:update', 'admin:workflow:delete'])) {
    columns.push({
      id: 'action',
      resizable: false,
      sortable: false,
      filterable: false,
      width: 63,
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id, name, readOnly, accountId } = original;

        const editable = utils.isEditable(accountId, activeAccountId, readOnly);
        const deletable = utils.isDeletable(accountId, activeAccountId);

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:workflow:update'],
            action: () => {
              openWorkflowEditor(original);
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:workflow:delete'],
            isDisabled: !deletable,
            tooltipContent: 'Only the owner can delete this',
            action: () => {
              setDeleteObj({ id, name });
              setActionMenuId('');
              setShowConfirm(true);
            },
          },
        ];

        return (
          <ActionButton
            isDisabled={!editable}
            isOpen={actionMenuId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
            tooltipContent="Only the owner can edit this"
          />
        );
      },
    });
  }

  if (pageViewState === 'edit') {
    return (
      <WorkflowEditPanel
        id={workflowId}
        templateId={templateId}
        goBackToEdit={() => {
          setPageViewState('view');
          updateURL();
        }}
        onSave={(workflowID, wf, isNew) => {
          const updatedArr = isNew ? addObjToArray(tableState.data, wf) : updateObjInArray(tableState.data, wf, wf.id);

          setTableData({
            data: updatedArr,
            totalResults: updatedArr.length,
          });

          if (workflowID) {
            updateURL('edit', workflowID); // Update URL with the new ID
          }
        }}
        onDelete={(id) => {
          const updatedTableData = deleteObjFromArray(tableState.data, id);

          setTableData({
            data: updatedTableData,
            totalResults: updatedTableData.length,
          });
          setPageViewState('view');
          updateURL();
        }}
      />
    );
  }

  if (pageViewState === 'create') {
    return (
      <WorkflowCreatePanel
        onCreateWorkflow={createWorkflow}
        goBackToEdit={() => {
          setPageViewState('view');
          updateURL('view');
        }}
      />
    );
  }

  const cancelCallback = () => {
    setShowConfirm(false);
    setDeleteObj();
  };

  const confirmCallback = () => {
    deleteAction(
      deleteObj.id,
      () => {
        toast.success('Workflow successfully deleted');

        const updatedTableData = deleteObjFromArray(tableState.data, deleteObj.id);

        setTableData({
          data: updatedTableData,
          totalResults: updatedTableData.length,
        });
      },
      () => {
        toast.error('Error deleting workflow');
      },
    );

    setShowConfirm(false);
  };

  return (
    <>
      <PageColumn className={cx('overflow-visible', { 'h-scroll-table': tableState.data.length })} state={pageState}>
        <EnhancedCard>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h3 className="mb-0">Workflows</h3>
            <RestrictedCreateButton
              action={() => {
                setPageViewState('create');
                updateURL('create');
              }}
              floatRight={false}
              label="Create Workflow"
              permissions={['admin:workflow:create']}
            />
          </div>
          <div className="d-flex align-items-center gap-3 mb-4">
            <SearchInput
              value={searchTerm}
              className="w-75"
              type="text"
              placeholder="Search Workflows by Name"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Select
              classNamePrefix="react-select"
              placeholder="Select Form"
              className="flex-grow-1"
              value={selectedType}
              options={[
                { value: 'LIVE', label: 'Live' },
                { value: 'DRAFT', label: 'Draft' },
              ]}
              onChange={(value) => {
                setSelectedType(value);
              }}
            />
          </div>
          {!tableState.data.length ? (
            <ClientAdminNoDataMessage
              btnProps={{
                onClick: () => {
                  setPageViewState('create');
                  updateURL('create');
                },
                label: searchTerm?.length > 0 ? null : 'Create Workflows',
              }}
              message=""
              title={
                searchTerm?.length > 0 ? 'No Workflows match the filter' : 'You currently have no Workflows created'
              }
            />
          ) : (
            <StyledTable
              className="mt-3"
              data={tableState.data}
              errorText="There has been an error loading Workflows, please try again later"
              hasSelectColumn={false}
              id="workflows"
              isLoading={tableState.isLoading}
              isRejected={tableState.isRejected}
              noDataText="You currently have no Workflows"
              pageSize={tableState.rowsPerPage}
              showPagination={false}
              columns={columns}
              totalResults={tableState.totalResults}
            />
          )}
        </EnhancedCard>
      </PageColumn>
      <Confirmation
        cancelCallback={cancelCallback}
        confirmCallback={confirmCallback}
        content={`Are you sure you want to delete ${(deleteObj || {}).name || ''} workflow?`}
        show={showConfirm}
      />
    </>
  );
}

AdvancedWorkflow.propTypes = {
  isCreateMode: PropTypes.bool,
  totalAccounts: PropTypes.number,
  activeAccountId: PropTypes.string,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

AdvancedWorkflow.defaultProps = {
  isCreateMode: false,
  totalAccounts: 0,
  activeAccountId: null,
  tablePrefs: {
    pageSize: 25,
  },
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
      },
    },
  } = state;

  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccountId,
  };
}

export default connect(mapStateToProps)(AdvancedWorkflow);
