import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CancelButton, CreateButton, EditButton } from '@Base/Buttons';
import { useFormKeypress } from '@Base/hooks';
import { Confirmation } from '@Base/Modal';
import { Form } from '@Base/Forms/Custom/CommonComponents';

import { createIDPConfig, getIDPConfig, updateIDPConfig } from '@API/AccountAPI/IDPAPI';
import { retryableAPICall } from '@API/common-api-utils';

import { isValidHttpUrl } from '@JS/utils/general-utils';
import { requestStatuses } from '@JS/constants/requestStatuses';

import { EnhancedCard, EnhancedCardTitle, IntegrationInput, PageColumn } from '../Common';

function SingleSignOn() {
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [errorLoading, setErrorLoading] = useState(undefined);

  async function getIDPConfigs() {
    const result = await getIDPConfig();

    if (typeof result === 'string') {
      setErrorLoading(true);
      toast.error('Could not retrieve SSO configurations at this time, please try again later');
    } else if (result.length > 0) {
      const { authEndpoint, clientId, clientSecret, domains, tokenEndpoint, userInfoEndpoint, id } = result[0];
      setFormData({ authEndpoint, clientId, clientSecret, domains, id, tokenEndpoint, userInfoEndpoint });
    } else {
      setIsNew(true);
    }
  }

  useEffect(() => {
    getIDPConfigs();
  }, []);

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  async function handleDelete() {
    setFormData({});
    setIsNew(true);
  }

  async function handleSave() {
    const errs = {};
    // validate formdata
    Object.entries(formData).forEach(([k, v]) => {
      if (!v) {
        errs[k] = 'Field can not be empty';
      }
    });

    const urlFields = ['authEndpoint', 'tokenEndpoint', 'userInfoEndpoint'];

    urlFields.forEach((endpoint) => {
      if (!errs[endpoint] && !isValidHttpUrl(formData[endpoint])) {
        errs[endpoint] = 'Must be a valid url including https://';
      }
    });

    if (errs && Object.keys(errs).length === 0 && Object.getPrototypeOf(errs) === Object.prototype) {
      setIsSaving(true);
      let result;
      if (isEditing && !isNew) {
        result = await retryableAPICall(() => updateIDPConfig(formData));
      } else if (isNew) {
        result = await retryableAPICall(() => createIDPConfig(formData));
      } else {
        result = '';
      }

      if (typeof result === 'string' && result === requestStatuses.ALREADY_EXISTS_ERROR) {
        toast.error(
          'Unable to update configuration as the domains provided could not be added. Please contact support',
        );
      } else if (typeof result === 'string') {
        toast.error('Unable to update configuration due to an error, please try again later or contact support');
      } else {
        toast.success('Successfully updated configuration');
        setIsEditing(false);
      }
      setIsSaving(false);
    } else {
      setErrors(errs);
    }
  }

  return (
    <PageColumn>
      <EnhancedCard className="mb-4">
        <EnhancedCardTitle
          title="OAuth Configuration"
          subtitle="Manage OAuth which allows an external system to be used as an
                  authentication platform with the ATS"
        />
        <div className="tw-p-6">
          <div className={`tw-flex tw-flex-wrap tw--mx-4 tw-mb-3`}>
            <div className={`tw-px-4 tw-w-full`}>
              {isEditing && !errorLoading && !isNew && (
                <p style={{ marginBottom: '10px' }}>
                  Please enter the OAuth credentials below to configure an external system to be used as an
                  authentication platform with the ATS. Note that your authentication service must support the OAuth
                  standard in order to use this functionality
                </p>
              )}
              {!isEditing && !errorLoading && !isNew && (
                <p style={{ marginBottom: '10px' }}>
                  Below are the OAuth credentials for using an external authentication system to allow users access to
                  the ATS
                </p>
              )}
              {isNew && !errorLoading && (
                <p style={{ marginBottom: '10px' }}>
                  OAuth allows the configuration of an external system supporting the{' '}
                  <a href="https://oauth.net/2/" rel="noreferrer" target="_blank">
                    OAuth standard
                  </a>{' '}
                  to authenticate users. Once configured your organisations users are able to use your authentication
                  service to login to the ATS. Initially users that have not logged in will go into a pending state so
                  that they can be authorised by a trusted party before being able to access any data.
                </p>
              )}
            </div>
          </div>
          <Form innerRef={formRef}>
            <IntegrationInput
              error={errors.domains}
              id="domains"
              isEditing={isEditing}
              label="Domains (Comma seperated)"
              onChange={(val) =>
                handleChange(
                  'domains',
                  val.split(',').map((itm) => itm.trim()),
                )
              }
              value={formData.domains || ''}
            />
            <IntegrationInput
              error={errors.clientId}
              id="clientId"
              isEditing={isEditing}
              label="Client ID"
              onChange={(val) => handleChange('clientId', val)}
              value={formData.clientId || ''}
            />
            <IntegrationInput
              error={errors.clientSecret}
              hideValue
              id="clientSecret"
              isEditing={isEditing}
              label="Client Secret"
              onChange={(val) => handleChange('clientSecret', val)}
              value={formData.clientSecret || ''}
            />
            <IntegrationInput
              error={errors.authEndpoint}
              id="authEndpoint"
              isEditing={isEditing}
              label="Authorization Endpoint"
              onChange={(val) => handleChange('authEndpoint', val)}
              value={formData.authEndpoint || ''}
            />
            <IntegrationInput
              error={errors.tokenEndpoint}
              id="tokenEndpoint"
              isEditing={isEditing}
              label="Token Endpoint"
              onChange={(val) => handleChange('tokenEndpoint', val)}
              value={formData.tokenEndpoint || ''}
            />
            <IntegrationInput
              error={errors.userInfoEndpoint}
              id="userInfoEndpoint"
              isEditing={isEditing}
              label="Userinfo Endpoint"
              onChange={(val) => handleChange('userInfoEndpoint', val)}
              value={formData.userInfoEndpoint || ''}
            />

            {!isEditing ? (
              <>
                <EditButton
                  action={() => setIsEditing(true)}
                  className="mt-2"
                  floatRight={false}
                  label={isNew ? 'Configure OAuth' : 'Edit Configuration'}
                />
                <CancelButton
                  action={(e) => {
                    e.preventDefault();
                    setShowDeleteWarning(true);
                  }}
                  className="mt-2"
                  disabled={isSaving}
                  isLoading={isSaving}
                  label="Delete"
                />
              </>
            ) : (
              <CreateButton
                action={(e) => {
                  e.preventDefault();
                  handleSave(isEditing, isNew);
                }}
                className="mt-2"
                disabled={isSaving}
                floatRight={false}
                isLoading={isSaving}
                label={isSaving ? 'Configuring...' : 'Configure'}
              />
            )}
            {isEditing && (
              <CancelButton
                action={() => {
                  setIsEditing(false);
                  setErrors({});
                }}
                className="mt-2 ms-2"
                disabled={isSaving}
                floatRight={false}
                isLoading={isSaving}
              />
            )}
          </Form>
        </div>
      </EnhancedCard>
      <Confirmation
        cancelCallback={() => setShowDeleteWarning(false)}
        confirmCallback={() => {
          handleDelete();
          setShowDeleteWarning(false);
        }}
        content={
          <p>
            <strong style={{ color: 'red', fontSize: '20px' }}>Warning!</strong>
            <br />
            <br /> Deleting this configuration will result in any users logging in by this method being unable to access
            their accounts and may result in data loss for those users. <br />
            <br />
            You should be <strong>absolutely sure</strong> you want to perform this action before confirming.
          </p>
        }
        show={showDeleteWarning}
      />
    </PageColumn>
  );
}

export default SingleSignOn;
