import React, { useState } from 'react';
import { CardBody, Input, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { updateVacancyCategories } from '@API/VacancyAPI';

import { useLanguagePack } from '@Base/hooks';
import { CreateButton, IconButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';

import { setCategories } from '@JS/actions/categoriesActions';

import { EnhancedCard, PageColumn, EnhancedCardTitle } from '../../Common';

function VacancySettings() {
  const languagePack = useLanguagePack('vacancy-settings');
  const [newCategoryValue, setNewCategoryValue] = useState('');
  const [categoryToRemove, setCategoryToRemove] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const { categories, isLoading } = useSelector((state) => state.categoryReducer);

  function saveCategories(categoriesToSave) {
    const sortedCategories = [...categoriesToSave].sort((a, b) => a.label.localeCompare(b.label));

    return updateVacancyCategories(sortedCategories)
      .then((response) => {
        dispatch(setCategories(response.categories));
      })
      .catch(() => {
        setIsError(true);
        toast.error(languagePack.couldNotUpdateCategories);
      });
  }

  function removeCategory() {
    const updatedCategories = categories.filter((category) => category.id !== categoryToRemove.id);

    saveCategories(updatedCategories).finally(() => {
      setCategoryToRemove(undefined);
    });
  }

  function addCategory() {
    const updatedCategories = [...categories, { id: newCategoryValue, label: newCategoryValue }];

    saveCategories(updatedCategories).then(() => {
      setNewCategoryValue('');
    });
  }

  return (
    <>
      <PageColumn
        state={{
          isPending: isLoading,
        }}
      >
        <EnhancedCard>
          <EnhancedCardTitle title="Vacancy Settings" subtitle="Manage your vacancy data" />
          <CardBody>
            <div className="mb-3">
              <h5>{languagePack.rolesHeading}</h5>
              <p>{languagePack.rolesExplanation}</p>
            </div>
            <>
              {categories.length > 0 && (
                <Table style={{ width: '500px' }}>
                  <tbody>
                    {categories.map((category, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={`${category.label}-${index}`}>
                        <td>{category.label}</td>
                        <td width={50}>
                          <IconButton
                            iconOnly
                            iconClassName="fa fa-times"
                            size="sm"
                            action={() => setCategoryToRemove(category)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {categories.length === 0 && !isError && (
                <div className="mb-3">
                  <p className="font-weight-bold">{languagePack.noCategoriesLabel}</p>
                </div>
              )}
              {isError && (
                <div className="mb-3">
                  <p className="font-weight-bold">{languagePack.retrieveCategoryError}</p>
                </div>
              )}
            </>

            {!isError && !isLoading && (
              <>
                <Input
                  placeholder={languagePack.addCategoryPlaceholder}
                  className="mb-1"
                  style={{ width: '500px' }}
                  value={newCategoryValue}
                  onChange={(e) => setNewCategoryValue(e.currentTarget.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') addCategory();
                  }}
                />
                <CreateButton
                  floatRight={false}
                  label={languagePack.addCategoryButtonLabel}
                  action={addCategory}
                  disabled={!newCategoryValue || newCategoryValue === ''}
                />
              </>
            )}
          </CardBody>
        </EnhancedCard>
      </PageColumn>
      <Confirmation
        content={languagePack.removeAreYouSureCategoryContent}
        title={`${languagePack.removeAreYouSureCategoryTitle} ${categoryToRemove?.label}`}
        confirmCallback={removeCategory}
        show={!!categoryToRemove}
      />
    </>
  );
}

export default VacancySettings;
